//TODO: ao clicar num link fechar o menu mobile

export const menuMobile = () => {
    const burguerMenu = document.querySelector('.burguer-menu');
    const menuMobile = document.querySelector('.menu-mobile-container');
    const menuMobileItemsWithSubmenu = Array.from(document.querySelectorAll('.menu-mobile__item-submenu'));
    const body = document.querySelector('body');
    if(!burguerMenu) return;
    if(!menuMobile) return;
    burguerMenu.addEventListener('click', (e) => {
        burguerMenu.classList.toggle('burguer-menu--active');
        menuMobile.classList.toggle('menu-mobile-containe--openned');
        body.classList.toggle('mobile-menu-is-open');
    });
    menuMobile.addEventListener('click', (e) => {
        if(e.target.classList[0] === menuMobile.classList[0]) {
            menuMobile.classList.toggle('menu-mobile-containe--openned');
            burguerMenu.classList.toggle('burguer-menu--active');
            body.classList.toggle('mobile-menu-is-open');
        }
    });
    if(!menuMobileItemsWithSubmenu) return;
    menuMobileItemsWithSubmenu.map(menuItem => {
        menuItem.addEventListener('click', () => {
            menuItem.classList.toggle('menu-mobile__item--show-submenu');
        });
    });
};
