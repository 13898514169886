import { Swiper, Navigation, Pagination, Autoplay } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay]);

export default () => {
    new Swiper('.main-banner', {
        speed: 1000,
        navigation: {
            nextEl: '.main-banner__button-controls .prev',
            prevEl: '.main-banner__button-controls .next',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        autoplay: {
           delay: 2000,
        },
    });
};
